.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
}
.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}

.table>:not(:first-child) {
  border-top: 2px solid;
}
.table-borderless>:not(:first-child) {
  border-top-width: 0;
}

.svg-icon{
  svg{
    max-width: 30px !important;
    max-height: 30px !important;
    width: 100%;
    height: 100%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid $primary;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }